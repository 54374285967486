<div [class.invalid]="invalid && touched" class="form-field">

  <label class="form-label">

    {{label()}}

    @if (isRequired) {
      <sup>*</sup>
    }

    <div class="control-container">
      <ng-content></ng-content>
    </div>

  </label>

  @if (invalid && touched) {
    <div [@errorInOut] class="error-message">{{ message }}</div>
  }

</div>
