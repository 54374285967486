.form-field {
  margin-bottom: 16px;

  &.invalid {
    .form-control,
    .form-select {
      border-color: var(--bs-danger);

      &:focus {
        box-shadow: 0 0 0 4px rgba(var(--bs-danger-rgb), 0.25)
      }
    }
  }

  .form-label {
    width: 100%;
  }
}

.error-message {
  margin-top: 8px;
  color: var(--bs-danger);
  font: {
    size: 12px;
    weight: 500;
  }
  line-height: 16px;
}
